<template>
  <div>
    <vx-card class="" no-shadow card-border>
      <div class="vx-row mb-6">
        <div class="vx-col w-full align-center my-2">
          <span>Taxis & Transfers Rule Criteria:</span>
        </div>
        <!--#region  suppliers field-->
        <div class="vx-col w-full md:w-1/2">
          <div class="vx-col w-full text-sm">
            <span>Suppliers:</span>
          </div>
          <div class="mt-4">
            <v-select
              multiple
              name="transferSuppliers"
              v-model="transferLocalForm.transferRuleCriteria.suppliers"
              :reduce="(x) => x.code"
              :disabled="readOnly"
              :options="priceRulesFormData.transferSupplier"
              v-validate="{ rules: { required: false } }"
            />
            <!-- <span class="text-danger text-sm" v-if="errors.has('transferSuppliers')">{{ errors.first('transferSuppliers') }}</span> -->
          </div>
        </div>
        <!--#endregion  suppliers field-->

        <!--#region  Transfer Type field-->
        <div class="vx-col w-full md:w-1/2">
          <div class="vx-col w-full text-sm">
            <span>Transfer Types:</span>
          </div>
          <div class="mt-4">
            <v-select
              multiple
              name="transferTypes"
              v-model="transferLocalForm.transferRuleCriteria.transferTypes"
              :reduce="(x) => x.code"
              :disabled="readOnly"
              :options="transferLocalHelper.enums.transferTypes"
              v-validate="{ rules: { required: false } }"
            />
            <!-- <span class="text-danger text-sm" v-if="errors.has('transferSuppliers')">{{ errors.first('transferSuppliers') }}</span> -->
          </div>
        </div>
        <!--#endregion  Transfer Type end-->
      </div>
      <div class="vx-row mb-6">
        <!--#region Transfer  Durations   -->
        <div class="vx-col w-full md:w-1/2">
          <div class="vx-col w-full text-sm">
            <span>Transfer Durations:</span>
          </div>
          <div class="mt-4">
            <v-select
              multiple
              v-model="transferLocalForm.transferRuleCriteria.transferDurations"
              :options="transferLocalHelper.numberArray(0, 365)"
              :disabled="readOnly"
            />
            <span class="text-danger text-sm">{{
              errors.first("transferDurations")
            }}</span>
          </div>
        </div>
        <!--#endregion Transfer  Durations ends -->

        <!--#region Country field -->
        <div class="vx-col w-full md:w-1/2">
          <div class="vx-col w-full text-sm">
            <span>Country:</span>
          </div>
          <div class="mt-4">
            <v-select
              multiple
              v-model="transferLocalForm.transferRuleCriteria.countries"
              :reduce="(x) => x.code"
              :disabled="readOnly"
              :options="priceRulesFormData.countryCode"
            />
            <span class="text-danger text-sm">{{
              errors.first("countries")
            }}</span>
          </div>
        </div>
        <!--#endregion Country field ends -->
      </div>
      <!--#region Locations  field  -->
      <div class="vx-row mb-6">
        <div class="vx-col w-full text-sm">
          <span>Locations:</span>
        </div>
        <template
          v-for="(translocation, translocationIndex) in transferLocalForm
            .transferRuleCriteria.locations"
        >
          <!-- Origin -->
          <div class="vx-col w-full md:w-1/3" :key="translocationIndex + '0'">
            <div class="mt-4">
              <label class="text-sm">MatchType</label>
              <v-select
                class="w-full"
                :name="'transLocationMatch_' + translocationIndex"
                v-model="
                  transferLocalForm.transferRuleCriteria.locations[
                    translocationIndex
                  ].matchType
                "
                :options="transferLocalHelper.enums.matchTypes"
                :reduce="(option) => option.code"
                v-validate="{ rules: { required: true} }"
                :disabled="readOnly"
              />
              <span
                class="text-danger text-sm"
                v-if="errors.has('transLocationMatch_' + translocationIndex)"
                >{{
                  errors
                    .first("transLocationMatch_" + translocationIndex)
                    .replace("transLocationMatch_" + translocationIndex, "Location match type")
                }}</span
              >
            </div>
          </div>
          <!-- Value -->
          <div class="vx-col w-full md:w-1/3" :key="translocationIndex + '1'">
            <div class="mt-4">
              <label class="text-sm">Value</label>
              <vs-input
                class="w-full"
                :name="'transLocationValue_' + translocationIndex"
                v-model="
                  transferLocalForm.transferRuleCriteria.locations[
                    translocationIndex
                  ].value
                "
                v-validate="{ rules: { required: true, max: 25 } }"
                :disabled="readOnly"
              />
              <span
                class="text-danger text-sm"
                v-if="errors.has('transLocationValue_' + translocationIndex)"
                >{{
                  errors
                    .first("transLocationValue_" + translocationIndex)
                    .replace(
                      "transLocationValue_" + translocationIndex,
                      "value"
                    )
                }}</span
              >
            </div>
          </div>
          <div class="vx-col w-full md:w-1/3" :key="translocationIndex + '2'">
            <div class="mt-3">
              <!-- <label class="text-sm">Max</label> -->
              <vs-button
                @click="
                  removetransDynamicFields(translocationIndex, 'translocation')
                "
                size="small"
                class="mt-8"
                type="border"
                color="danger"
                :class="readOnly ? 'opacity-50 cursor-not-allowed' : ''"
                :disabled="readOnly"
                >Remove</vs-button
              >
            </div>
          </div>
        </template>

        <div class="vx-col w-full my-3">
          <vs-button
            @click="addtransDynamicFields('translocation')"
            size="small"
            type="border"
            color="success"
            :class="readOnly ? 'opacity-50 cursor-not-allowed' : ''"
            :disabled="readOnly"
            >Add</vs-button
          >
        </div>
      </div>
      <!--#endregion Location fields  ends -->

      <!--#region Transfer Companies  field  -->
      <div class="vx-row mb-6">
        <div class="vx-col w-full text-sm">
          <span>Transfer Companies:</span>
        </div>
        <template
          v-for="(transCompany, transCompanyIndex) in transferLocalForm
            .transferRuleCriteria.transferCompanies"
        >
          <!-- Origin -->
          <div class="vx-col w-full md:w-1/3" :key="transCompanyIndex + '0'">
            <div class="mt-4">
              <label class="text-sm">MatchType</label>
              <v-select
                class="w-full"
                :name="'transCompanyMatch_' + transCompanyIndex"
                v-model="
                  transferLocalForm.transferRuleCriteria.transferCompanies[
                    transCompanyIndex
                  ].matchType
                "
                :options="transferLocalHelper.enums.matchTypes"
                :reduce="(option) => option.code"
                v-validate="{ rules: { required: true} }"
                :disabled="readOnly"
              />
               <span
                class="text-danger text-sm"
                v-if="errors.has('transCompanyMatch_' + transCompanyIndex)"
                >{{
                  errors
                    .first("transCompanyMatch_" + transCompanyIndex)
                    .replace("transCompanyMatch_" + transCompanyIndex, "Company match type")
                }}</span
              >
            </div>
          </div>
          <!-- Value -->
          <div class="vx-col w-full md:w-1/3" :key="transCompanyIndex + '1'">
            <div class="mt-4">
              <label class="text-sm">Value</label>
              <vs-input
                class="w-full"
                :name="'transCompanyValue_' + transCompanyIndex"
                v-model="
                  transferLocalForm.transferRuleCriteria.transferCompanies[
                    transCompanyIndex
                  ].value
                "
                v-validate="{ rules: { required: true, max: 25 } }"
                :disabled="readOnly"
              />
              <span
                class="text-danger text-sm"
                v-if="errors.has('transCompanyValue_' + transCompanyIndex)"
                >{{
                  errors
                    .first("transCompanyValue_" + transCompanyIndex)
                    .replace("transCompanyValue_" + transCompanyIndex, "value")
                }}</span
              >
            </div>
          </div>
          <div class="vx-col w-full md:w-1/3" :key="transCompanyIndex + '2'">
            <div class="mt-3">
              <!-- <label class="text-sm">Max</label> -->
              <vs-button
                @click="
                  removetransDynamicFields(transCompanyIndex, 'transcompany')
                "
                size="small"
                class="mt-8"
                type="border"
                color="danger"
                :class="readOnly ? 'opacity-50 cursor-not-allowed' : ''"
                :disabled="readOnly"
                >Remove</vs-button
              >
            </div>
          </div>
        </template>

        <div class="vx-col w-full my-3">
          <vs-button
            @click="addtransDynamicFields('transcompany')"
            size="small"
            type="border"
            color="success"
            :class="readOnly ? 'opacity-50 cursor-not-allowed' : ''"
            :disabled="readOnly"
            >Add</vs-button
          >
        </div>
      </div>
      <!--#endregion Location fields  ends -->
    </vx-card>
  </div>
</template>

<script>
import _ from "lodash";
import { CustomValidator } from "../VoucherRuleEditorValidator.js";
const customvalidator = new CustomValidator();
customvalidator.setupErrorMessages();

export default {
  name: "TransferRuleCriteria",
  components: {},
  inject: ["$validator"],
  props: {
    helper: {
      type: Object,
    },
    form: {
      type: Object,
    },
    priceRulesFormData: {
      type: Object,
    },
    readOnly: {
      type: Boolean,
    },
  },
  data() {
    return {
      transferLocalHelper: this.helper,
      transferLocalForm: this.form,
    };
  },

  methods: {
    validateform() {
      return this.$validator.validateAll();
    },
    //#region  Adding dynamic route fields in the form
    addtransDynamicFields(fieldType) {
      if (fieldType === "translocation") {
        this.transferLocalForm.transferRuleCriteria.locations.push(
          _.cloneDeep(this.transferLocalHelper.stringMatch)
        );
      }
      if (fieldType === "transcompany") {
        this.transferLocalForm.transferRuleCriteria.transferCompanies.push(
          _.cloneDeep(this.transferLocalHelper.stringMatch)
        );
      }
    },
    //#endregion

    //#region  Removing dynamic route fields in the form
    removetransDynamicFields(index, fieldType) {
      if (fieldType === "translocation") {
        this.transferLocalForm.transferRuleCriteria.locations.splice(index, 1);
      }
      if (fieldType === "transcompany") {
        this.transferLocalForm.transferRuleCriteria.transferCompanies.splice(
          index,
          1
        );
      }
    },
    //#endregion
  },

  /**
   * When check box is unchecked, all its related form  should be reset   *
   */
  watch: {
    "form.productTypes": {
      deep: true,
      immediate: true,
      handler: function (newValue, oldValue) {
        if (
          oldValue &&
          oldValue.length > 0 &&
          oldValue.includes("Transfers") &&
          newValue &&
          !newValue.includes("Transfers")
        ) {
          this.transferLocalForm.transferRuleCriteria = _.cloneDeep(
            this.transferLocalHelper.transferCriteria
          );
        }
      },
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
