
//#region string formatter

//syntax : StringFormat("Hello {0} {1}", "beautiful", "World!") => Hello beautiful World!
const StringFormat = (str, ...args) =>
    str.replace(/{(\d+)}/g, (match, index) => args[index] || '');
//#endregion

//#region DateTime Formatter
const dateFormatter = {   
    date :(date,dateFormat) => {
        let createdDate = "";
        let currentMonth = new Date(date).getMonth() + 1;
        let month = currentMonth > 9 ? `${currentMonth}` : `0${currentMonth}`;
        let currentDay = new Date(date).getDate();
        let day = currentDay > 9 ? `${currentDay}` : `0${currentDay}`;
        if(dateFormat === 'dd/mm/yyyy'){
            createdDate = `${day}/${month}/${new Date(date).getFullYear()}`;
        }
        if(dateFormat === 'mm/dd/yyyy'){
            createdDate = `${month}/${day}/${new Date(date).getFullYear()}`;
        }
        return createdDate;
    },
    flatDate :(dateFormat) => {
        let format = '';
        if(dateFormat === 'dd/mm/yyyy'){
            format = 'd/m/Y';
        }
        if(dateFormat === 'mm/dd/yyyy'){
            format = 'm/d/Y';
        }
        return format;
    },
  
}
//#endregion

//#region generating number of array
const numberArray = (start,end) => {return Array(end - start + 1).fill().map((_, idx) => start + idx)};
//#endregion

//#region Backend class equivalent to json object
const priceRange = {
    min: 0,
    max: 0,

};
const dateRange = {
    min: null,
    max: null,
    daysOfWeek: [0,1,2,3,4,5,6],
};

const passengerAllocation = {
    adults: 0,
    children: 0,
    infants: 0,

};
const flightCriteria = {
    suppliers: null,
    carrierCodes: null,
    cabinClasses: null,
    fareTypes: null,
    routes: [],//[{...flightRoute}],
    fareBases : [],//[{...stringMatch}]

};

const accommodationCriteria = {
    suppliers: null,
    supplierCodes: null,
    propertyTypes: null,
    ratings: [],//[0],
    names: [],//[{...stringMatch}],
    brands: [],//[{...stringMatch}],
    chains: [],//[{...stringMatch}],
    roomNames: [],//[{...stringMatch}],
    locations: [],//[{...stringMatch}],
    countries: null,
    boardBases : null

};

const packageHolidayCriteria = {
    suppliers: null,
    propertyTypes: null,
    ratings: [],//[0],
    names: [],//[{...stringMatch}],
    roomNames: [],//[{...stringMatch}],
    brands: [],//[{...stringMatch}],
    locations: [],//[{...stringMatch}],
    countries: null,
    boardBases : null

};

const carCriteria = {
    suppliers: null,
    rentalOperators: null,
    transmissions: null,
    classCodes: [],
    sippCodes: [],
    rentalDurations: null,
    locationMatchType: "Any",
    locations: [],//[{...stringMatch}],
    countries: null,
    packageNames: [],//[{...stringMatch}]

};
const transferCriteria = {
    suppliers: null,
    transferCompanies: [],//[{...stringMatch}],
    transferTypes: null,
    transferDurations: [],
    locations: [],//[{...stringMatch}],
    countries: null,
};
const ticketsCriteria = {
    suppliers: null,
    ticketTypes: null,
    locations: [],//[{...stringMatch}],
    countries: null,
};
const airportExtrasCriteria = {
    suppliers: null,
    extraTypes: null,
    airports: null
};
const flightRoute = {
    origin: null,
    destination: null
};
const stringMatch = {
    value: null,
    matchType: 'Exact'

};


//#endregion

//#region  enums equivalent 
const enums = {
    searchTypes: [
        { value: "Flights", displayName: "Flights" },
        { value: "Accommodation", displayName: "Accommodation" },
        { value: "Packages", displayName: "Packages" },
        { value: "TicketsAttractions", displayName: "Tickets Attractions" },
        { value: "Transfers", displayName: "Transfers" },
        { value: "AirportExtras", displayName: "Airport Extras" },
        { value: "Insurance", displayName: "Insurance" },
        { value: "CarRental", displayName: "CarRental" },
        { value: "Visas", displayName: "Visas" },        
        { value: "Tours", displayName: "Tours" },
        { value: "DynamicPackaging", displayName: "Dynamic Packaging" },
        { value: "TicketAccommodation", displayName: "Ticket + Accommodation" },
        { value: "Cruise", displayName: "Cruise" },
    ],
    productTypes: [
        // { value: "Packages", displayName: "Packages" , componentName: ""},
        // { value: "Insurance", displayName: "Insurance", componentName: "" },        
        // { value: "Visas", displayName: "Visas" , componentName: ""},
        // { value: "Itinerary", displayName: "Itinerary" , componentName: ""},        
        // { value: "Tours", displayName: "Tours", componentName: "" },
        { value: "Flights", displayName: "Flights", componentName: "FlightRuleCriteria" },
        { value: "Accommodation", displayName: "Accommodation", componentName: "AccommodationRuleCriteria" },
        { value: "TicketsAttractions", displayName: "Tickets Attractions/Custom Extras" , componentName: "TicketsCriteria"},
        { value: "Transfers", displayName: "Transfers", componentName: "TransferRuleCriteria" },
        { value: "AirportExtras", displayName: "Airport Extras", componentName: "AirportExtrasCriteria" },
        { value: "CarRental", displayName: "CarRental", componentName: "CarCriteria" },
        { value: "Packages", displayName: "Packages", componentName: "PackageHolidayRuleCriteria" },
    ],
    suppliers: [        
        { label: 'Hitch-Hiker', code: 'HitchHiker' },
        { label: 'Gold Medal DNATA', code: 'GoldMedalDNATA' },
        { label: 'HotelBeds', code: 'HotelBeds' },
        { label: 'Ingresso', code: 'Ingresso' },
        { label: 'Hoppa', code: 'Hoppa' },
        { label: 'Holiday Extras', code: 'HolidayExtras' },
        { label: 'Flexible Autos', code: 'FlexibleAutos' },
        { label: 'AvisBudget', code: 'AvisBudget' },
        { label: 'VisaHQ', code: 'VisaHQ' },
        { label: 'EAN', code: 'EAN' },
        { label: 'Insightly', code: 'Insightly' },
        { label: 'AirHobStays', code: 'AirHobStays' },
        { label: 'HolidayTaxis', code: 'HolidayTaxis' },
        { label: 'GAdventures', code: 'GAdventures' },
        { label: 'GoogleAnalytics', code: 'GoogleAnalytics' },
        { label: 'Homestay', code: 'Homestay' },
        { label: 'ApartmentsApart', code: 'ApartmentsApart' },
        { label: 'Mailchimp', code: 'Mailchimp' },
        { label: 'Interhome', code: 'Interhome' },
        { label: 'DemoFlights', code: 'DemoFlights' },
        { label: 'DemoAccommodation', code: 'DemoAccommodation' },
        { label: 'SygicData', code: 'SygicData' },
        { label: 'DemoCarRental', code: 'DemoCarRental' },
        { label: 'DemoTickets', code: 'DemoTickets' },
        { label: 'Leezair', code: 'Leezair' },
        { label: 'Traveltek', code: 'Traveltek' },
        { label: 'YpsilonFlights', code: 'YpsilonFlights' },
        { label: 'Paxport', code: 'Paxport' },
        { label: 'Stripe', code: 'Stripe' },
        { label: 'Braintree', code: 'Braintree' },
        { label: 'ArrivalGuides', code: 'ArrivalGuides' },
        { label: 'WalkScore', code: 'WalkScore' },
        { label: 'Encharge', code: 'Encharge' },
        { label: 'MailerLite', code: 'MailerLite' },
        { label: 'Unknown', code: 'Unknown' },
    ],
    carrierCodes :[
        { label: 'FM', code: 'FM' },
        { label: 'AB', code: 'AB' },
        { label: 'OS', code: 'OS' },
    ],
    cabinClasses:[
        { label: 'Any', code: 'Any' },
        { label: 'Economy', code: 'Economy' },
        { label: 'Premium Economy', code: 'PremiumEconomy' },
        { label: 'Business', code: 'Business' },
        { label: 'First', code: 'First' },

    ],
    fareTypes:[
        { label: 'Private ', code: 'Private' },
        { label: 'Published ', code: 'Published' },
        { label: 'Negotiated ', code: 'Negotiated' },
        { label: 'Nett ', code: 'Nett' },
        { label: 'Cat35 ', code: 'Cat35' },
        { label: 'LowCost  ', code: 'LowCost' },
        { label: 'Charter  ', code: 'Charter' },
        { label: 'Corporate  ', code: 'Corporate' },
        { label: 'Web  ', code: 'Web' },
        { label: 'ITX  ', code: 'ITX' },
        { label: 'ITXCat35  ', code: 'ITXCat35' },
    ],
    airportExtraTypes:[
        { label: 'Any ', code: 'Any' },
        { label: 'Lounge ', code: 'Lounge' },
        { label: 'Parking ', code: 'Parking' },
        { label: 'Hotel ', code: 'Hotel' },
        { label: 'Hotel With Parking ', code: 'HotelWithParking' },
        { label: 'Fast Track  ', code: 'FastTrack' },        

    ],
    transmissions:[
        { label: 'Unknown ', code: 'Unknown' },
        { label: 'Manual ', code: 'Manual' },
        { label: 'Automatic ', code: 'Automatic' },
    ],
    rentalOperators:[
        { label: 'R1 ', code: 'R1' },
        { label: 'R2 ', code: 'R2' },
        { label: 'R3 ', code: 'R3' },

    ],
    classCodes:[
        { label: 'C1 ', code: 'C1' },
        { label: 'C2 ', code: 'C2' },
        { label: 'C3 ', code: 'C3' },

    ],
    ssipCodes:[
        { label: 'S1 ', code: 'S1' },
        { label: 'S2 ', code: 'S2' },
        { label: 'S3 ', code: 'S3' },

    ],
    propertyTypes:[
        { label: 'Unknown ', code: 'Unknown' },
        { label: 'Hotel ', code: 'Hotel' },
        { label: 'Apartment ', code: 'Apartment' },
        { label: 'Villa ', code: 'Villa' },
        { label: 'House ', code: 'House' },
        { label: 'Boat ', code: 'Boat' },
        { label: 'Yacht ', code: 'Yacht' },
        { label: 'Bungalow ', code: 'Bungalow' },
        { label: 'Chalet ', code: 'Chalet' },
        { label: 'Farmhouse ', code: 'Farmhouse' },
        { label: 'Guesthouse ', code: 'Guesthouse' },
        { label: 'CastleMansion ', code: 'CastleMansion' },

    ],
    matchTypes:[
        { label: 'Exact ', code: 'Exact' },
        { label: 'PartialAnywhere ', code: 'PartialAnywhere' },
        { label: 'PartialStart ', code: 'PartialStart' },
        { label: 'PartialEnd  ', code: 'PartialEnd ' },

    ],
    locationMatchTypes:[
        { label: 'Any ', code: 'Any' },
        { label: 'OnAirport ', code: 'OnAirport' },
        { label: 'OffAirport ', code: 'OffAirport' },
    ],
    transferTypes:[
        { label: 'Shuttle ', code: 'Shuttle' },
        { label: 'Private ', code: 'Private' },
    ],
    ticketTypes:[
        { label: 'Any ', code: 'Any' },
        { label: 'Attraction ', code: 'Attraction' },
        { label: 'Event ', code: 'Event' },
        { label: 'Theatre ', code: 'Theatre' },
        { label: 'Exhibition ', code: 'Exhibition' },
        { label: 'Classes ', code: 'Classes' },
        { label: 'Sports ', code: 'Sports' },
        { label: 'CityPass ', code: 'CityPass' },
        { label: 'Tours ', code: 'Tours' },
        { label: 'Music ', code: 'Music' },
        { label: 'Conference ', code: 'Conference' },
        { label: 'Experience ', code: 'Experience' },
    ],
    suppliersCodes:[
        { label: 'S1 ', code: 'S1' },
        { label: 'S2 ', code: 'S2' },
        { label: 'S3 ', code: 'S3' },
    ],
    // flightRouteCodes supports 2 letter country code or 3 letter airport code
    flightRouteCodes :[
        { label: 'AAA', code: 'AAA' },
        { label: 'AAB', code: 'AAB' },
        { label: 'AAL', code: 'AAL' },
        { label: 'AD', code: 'AD' },
        { label: 'AG', code: 'AG' },
        { label: 'AM', code: 'AM' },        
    ],
    daysOfWeek : [
        { label: 'Sun', code: 0 },
        { label: 'Mon', code: 1 },
        { label: 'Tue', code: 2 },
        { label: 'Wed', code: 3 },
        { label: 'Thu', code: 4 },
        { label: 'Fri', code: 5 },
        { label: 'Sat', code: 6 },
    ],
    boardBasis: [
        { label: 'Room Only', code: 'RoomOnly' },
        { label: 'Self Catering', code: 'SelfCatering' },
        { label: 'Bed And Breakfast', code: 'BedAndBreakfast' },
        { label: 'Dinner Bed Breakfast', code: 'DinnerBedBreakfast' },
        { label: 'Half Board', code: 'HalfBoard' },
        { label: 'Full Board', code: 'FullBoard' },
        { label: 'All Inclusive', code: 'AllInclusive' },
        { label: 'All Inclusive Plus', code: 'AllInclusivePlus' },
        { label: 'Catered Chalet', code: 'CateredChalet' },
        { label: 'Assisted Chalet', code: 'AssistedChalet' },
        { label: 'Managed Chalet', code: 'ManagedChalet' },
        { label: 'Full Board Plus', code: 'FullBoardPlus' },
        { label: 'Catered Lodge', code: 'CateredLodge' },
        { label: 'Mixed', code: 'Mixed' }

    ]
   }
//#endregion

//#region API URLS
const depositRuleAPIs = {
    formDataAPI : "api/pricerules/formdata/{0}",
    // listAPI : "api/depositrules/apps/{0}/rules",
    addAPI : "api/depositrules/apps/{0}",
    updateAPI : "api/depositrules/apps/{0}/rules/{1}",
    deleteAPI : "api/depositrules/apps/{0}/rules/{1}",
};

//#endregion

//#region  model to be used in form
const priceRuleModel = {
    id: 0,
    applicationId: 0,
    name: "",
    priority: 0,
    isActive: false,
    depositOptions: [],
    minimumDaysBeforeTravel : null,
    dueDateDaysBeforeTravel : null,
    searchTypes: [],
    productTypes: [],
    prices: [],
    bookingDates: [],//[{ ...dateRange }],// default value 
    travelDates: [],//[{ ...dateRange }],// default value 
    passengerAllocations: [],//[{...passengerAllocation}],//default value
    flightRuleCriteria: flightCriteria, //{...flightCriteria},
    accommodationRuleCriteria: accommodationCriteria,
    packageHolidayRuleCriteria: packageHolidayCriteria,
    carRuleCriteria: carCriteria,
    transferRuleCriteria: transferCriteria,
    ticketsRuleCriteria: ticketsCriteria,
    airportExtrasRuleCriteria: airportExtrasCriteria,
    depositOptionId: [],
};
//#endregion



//#region dummy data
const priceRuleLists= [
    {"id":7,"applicationId":"100","name":"Rule Test","priority":"1",created: '2021-05-14',"isActive":true,"searchTypes":["CarRental"],"productTypes":["Flights","Transfers","Visas"],"bookingDates":[{"min":"2021-05-04","max":"2021-05-06","daysOfWeek":null}],"travelDates":[{"min":"2021-05-18","max":"2021-05-20","daysOfWeek":null}],"passengerAllocations":[{"adults":"2","children":"4","infants":"1"}],"flightRuleCriteria":{"suppliers":["Hoppa"],"carrierCodes":["A2"],"cabinClasses":["Business"],"fareTypes":["Private"],"routes":[{"origin":"AUS","destination":"US"}]},"accommodationRuleCriteria":{"suppliers":null,"supplierCodes":null,"propertyTypes":null,"ratings":null,"names":null,"roomNames":null,"brands":null,"chains":null},"carRuleCriteria":{"suppliers":null,"rentalOperators":null,"transmissions":null,"classCodes":null,"sippCodes":null},"transferRuleCriteria":{"suppliers":null},"ticketsRuleCriteria":{"suppliers":null},"airportExtrasRuleCriteria":{"suppliers":null,"extraTypes":null}},    
];

//#endregion  

const helper = {
    priceRuleModel: priceRuleModel,
    dateRange: dateRange,
    priceRange : priceRange,
    flightRoute: flightRoute,
    flightCriteria : flightCriteria,
    passengerAllocation : passengerAllocation,
    airportExtrasCriteria : airportExtrasCriteria,
    ticketsCriteria : ticketsCriteria,
    transferCriteria : transferCriteria,
    carCriteria : carCriteria,
    accommodationCriteria : accommodationCriteria,
    packageHolidayCriteria: packageHolidayCriteria,
    stringMatch : stringMatch,
    enums: enums,
    depositRuleAPIs: depositRuleAPIs,
    StringFormat: StringFormat,
    numberArray: numberArray,
    priceRuleLists: priceRuleLists,
    dateFormatter : dateFormatter
}

export default helper;



